import { useCallback, useEffect, useState } from 'react'
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap'
import { FaSearch } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import './css/search-bar.css'
import { useDispatch, useSelector } from 'react-redux'
import { getLoading, getSearch } from './selectors'
import { IRootState } from '../../../redux/reducers'
import { setSearchValue } from './actions'
import { useNavigate } from 'react-router-dom'
import Loading from '../../custom/Loading'

interface IProps {
    dontAnimate?: boolean;
}

const SearchBar: React.FC<IProps> = ({
    dontAnimate
}) => {

    const dispatch = useDispatch()
    const nagivate = useNavigate()

    const currentSearch = useSelector<IRootState, string>(getSearch)
    const loading = useSelector<IRootState, boolean>(getLoading)

    const [search, setSearch] = useState<string>(currentSearch)

    useEffect(() => {
        setSearch(currentSearch)
    }, [setSearch, currentSearch])

    const handleSearchChange = useCallback((e) => {
        setSearch(e.target.value)
    }, [setSearch])

    const handleSubmitSearch = useCallback(() => {
        dispatch(setSearchValue(search))
        nagivate(`/search?q=${search}`)
    }, [
        search,
        dispatch,
        nagivate
    ])

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter') {
            handleSubmitSearch()
        }
    }, [handleSubmitSearch])

    const handleClearSearch = useCallback(() => {
        setSearch('')
    }, [setSearch])

    return (
        <InputGroup className={`search-bar ${dontAnimate ? 'no-animation' : ''}`} >
            <Form.Control
                size='lg'
                placeholder='Buscar...'
                value={search}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                disabled={loading}
            />
            {
                search ? (
                    <Button
                        size='sm'
                        className='btn-clear'
                        onClick={handleClearSearch}
                        disabled={loading}
                    >
                        <MdClose />
                    </Button>
                ) : <></>
            }
            
            <Button
                className='btn-search'
                onClick={handleSubmitSearch}
                disabled={loading}
            >
                {
                    loading ? <Spinner animation='border' /> : <FaSearch />
                }
            </Button>
        </InputGroup>
    )
}

export default SearchBar