const AboutTest = () => {
    return (
      <div>
        <h3>Descripción</h3>
        <hr />
        <p>
            El objetivo de esta prueba es conocer tus habilidades como desarrollador Frontend con un reto que refleja el tipo de trabajo que tú podrías hacer en BodyTech.
        </p>
        <h3>Instrucciones</h3>
        <hr />
        <p>Debes escribir una aplicación que permita buscar canciones mediante el Web API pública de Spotify.</p>
        <ol>
            <li>
                Búsqueda: Una página con un formulario que consiste en un campo de texto para ingresar el término de búsqueda. Después de que el usuario llene el campo y presione envia, debajo del formulario se debe mostrar una lista paginada con los resultados.
            </li>
            <li>
                Detalle: Una pantalla que debe mostrar la información que consideres relevante sobre una canción. El usuario puede llegar a esta pantalla seleccionando uno de los resultados de la página de busqueda.
            </li>
        </ol>
        <h3>Consideraciones</h3>
        <ol>
            <li>
                Tienes <b>1</b> día para completar esta prueba, iniciado desde la fecha en la que recibiste este documento en tu correo electrónico.
            </li>
            <li>
                La aplicación debe estar desarrollada usando la última versión estable de React usando Hooks y es deseable que se utilice TypeScript.
            </li>
            <li>
                Incluye todas las intrucciones claras y detalladas de caulquier paso requerido para ejecutar la aplicación.
            </li>
            <li>
                Las buenas prácticas y estilo de código serán evaluadas.
            </li>
            <li>
                Debes incluir pruebas <b>end to end</b> para garantizar al funcionalidad, pordías usar herramientas como <b>cypress</b> o de preferencia.
            </li>
            <li>La aplicación debe verse correctamente en mobile y desktop (mobile first).</li>
            <li>Subir el código a un repositorio público en <a>GitHub</a></li>
            <li>Cualquier cosa no especificada arriba será de tu consideración.</li>
        </ol>
      </div>
    )
}

export default AboutTest