import { Button, Offcanvas } from 'react-bootstrap'
import { ReactComponent as Logo } from '../../../static/images/logo.svg'
import { ReactComponent as LogoSm } from '../../../static/images/logo-sm.svg'
import { MdArrowBack, MdInfo } from 'react-icons/md'
import './css/navbar.css'
import { useCallback, useMemo, useState } from 'react'
import AboutTest from './AboutTest'
import SearchBar from '../SearchBar/SearchBar'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { BsSpotify } from 'react-icons/bs'
import { FaSpotify } from 'react-icons/fa'
import { useMediaQuery } from '../../custom/hooks'

const NavBar: React.FC = () => {

    const location = useLocation()
    const navigate = useNavigate()

    const [showAbout, setShowAbout] = useState<Boolean>(false)

    const isMobile = useMediaQuery('(max-width: 960px)')

    const showSearchBar = useMemo(() => location.pathname === '/search', [location])
    const showGoBackButton = useMemo(() => location.pathname?.includes('/item'), [location])

    const handleShowAbout = useCallback(() => {
        setShowAbout(true)
    }, [setShowAbout])

    const handleHideAbout = useCallback(() => {
        setShowAbout(false)
    }, [setShowAbout])

    const handleGoBack = useCallback(() => {
        navigate(-1)
    }, [navigate])

    return (
        <div className='navbar'>
            <div className='navbar-left'>
                <Link to='/' >
                {
                    (showSearchBar || isMobile) ? 
                        <LogoSm className='logo' />
                        :
                        <Logo className='logo' />
                }
                </Link>
                {
                    showGoBackButton && (
                        <Button
                            variant='default'
                            size='sm'
                            title='Go back'
                            className='go-back'
                            onClick={handleGoBack}
                        >
                            <MdArrowBack />
                        </Button>
                    )
                }
                {
                    showSearchBar && (
                        <SearchBar dontAnimate={true} />
                    )
                }
            </div>
            <div className='navbar-buttons'>
                {/* <Button
                    variant='default'
                    className='spotify-login'
                >
                    <FaSpotify />&nbsp;Iniciar sesión
                </Button> */}
                <Button
                    variant='default'
                    onClick={handleShowAbout}
                >
                    <MdInfo />
                </Button>
                <Offcanvas
                    show={showAbout}
                    placement='end'
                    onHide={handleHideAbout}
                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title><h1>BodyTech Test</h1></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <AboutTest />    
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </div>
    )
}

export default NavBar