import { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"

export const useQuery = () => {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
}

export const useMediaQuery = (query: string) => {
    const [matches, setMatches] = useState(false);
  
    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
    }, [matches, query]);
  
    return matches;
}
  